.review-card-container {
  user-select: none;
  max-width: 300px;
  margin: 1rem auto;
  border: 1px solid #ffffff22;
  background-color: #ffffff;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  border-radius: 0.3rem;
  overflow: hidden;
  transition: 0.5s all;
}

.review-card-container:hover {
  border: 1px solid #ffffff44;
  box-shadow: 3px 4px 2px 2px #969292aa;
}

.main {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
}

.tokenImage {
  border-radius: 0.5rem;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.reviewer-name,
.review-action,
.rating,
.review-card-text {
  margin: 0.5rem 0;
  color: black;
}

.business-name {
  padding-top: 10px;
}

.business-name,
.rating {
  font-weight: 700;
}

.reviewer-name {
  margin: 10px;
  font-size: 16px;
  font-weight: 700;
}
