.business-images-index-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.business-images-index-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.business-images-index-title {
    align-self: flex-start;
}
.business-images-index-grid img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    filter: opacity(50%);
    cursor: pointer;
    transition: all 0.6s;
    transition-property: filter;
}

.business-images-index-grid img:hover {
    filter: opacity(100%);
    box-shadow: 2px 2px 2px gray;
}
