.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    text-align: center;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/189859/screenshots/3639645/media/84fb7cb80993abad733749343c577300.gif);
    height: 1000px;
    background-position: center;
    background-size: cover;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: rgba(224,7,7,1);
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}
