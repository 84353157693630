.manage-businesses-container {
  width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 1200px;
}

.manage-images-index-title {
  align-self: flex-start;
}

.manage-businesses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  max-width: 960px;
  margin: 0 auto;
}
