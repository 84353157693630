.business-delete-form-container {
  width: 80%;
  margin: auto;
}

.business-delete-form-button-container {
  display: flex;
  width: 150px;
  justify-content: space-around;
}

.business-delete-form-title {
  color: #d32323;
}

.business-delete-form-delete-button {
  cursor: pointer;
  color: #666;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 8px;
  background-image: none;
  background-repeat: no-repeat;
  transition: all 0.6s;
  transition-property: background-color, background-position, background-size,
    border-color, color;
}

.business-delete-form-delete-button:hover {
  cursor: pointer;
  color: #fff;
  background: #d32323;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-image: none;
  background-repeat: no-repeat;
}
