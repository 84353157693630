/* #modal {

} */

#modal-background {
  position: fixed;
  top: 70px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
display: flex;
justify-content: center;
}
