.business-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.business-card img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
}

.business-card h2 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
}

.business-card p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}

.fa-pen-to-square,
.fa-trash {
  color: rgba(2, 122, 151, 1);
}

.manage-business-text {
  color: rgba(2, 122, 151, 1);
  text-decoration: underline;
  cursor: pointer;
}
