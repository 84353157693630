.login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page-container > input {
  width: 235px;
  height: 25px;
  margin: 5px 0px;
  padding: 0px 5px;
}

.login-form-button {
  width: 250px;
  height: 35px;
  background-color: rgba(224, 7, 7, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.demo-user-button {
  width: 250px;
  height: 35px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

.new-platepal-container {
  margin-top: 10px;
  font-size: 12px;
}

.new-platepal-sign-up {
  color: #0073bb;
  text-decoration: none;
}

.login-form-errors{
  color: red;
}
